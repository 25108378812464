import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Castles } from "../lib/Data";
import { LocalLink } from "./LocalLink";

export const CastleList = () => {
    const { i18n } = useTranslation();

    return (
        <Grid>
            {Castles.sort(function(a,b){
                // Put current language first, than sort by name alphabetically
                if(a.lang === i18n.languages[0]) return -1;
                if(b.lang === i18n.languages[0]) return 1;
                return a.name > b.name ? 1 : -1;
            }).map(castle => <Castle key={castle.uid} castle={castle} />)}
        </Grid>
    )
}

const Castle = (props) => {
    const { image, name, city, province, lang, uri } = props.castle;
    return (
        <CastleBlock to={`/${uri}`}>
            <Image style={{ backgroundImage: `url('/images/${image}')` }} />
            <Name>{name}</Name>
            <Location>{city}, {province}</Location>
            <Language>{lang}</Language>
        </CastleBlock>
    )
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 32px;
  

    @media(max-width: 900px) {
        grid-template-columns: repeat(2, 1fr);
    }
`;

const CastleBlock = styled(LocalLink)`
    display: block;
    padding: 0px;
    cursor: pointer;
    background: none;
    outline: none;
    transition: transform .2s ease-in-out;
    position: relative;
    text-decoration: none;
    &:hover {
        transform: scale(1.05);
    }
`;

const Image = styled.div`
    display: block;
    /* height: 140px; */
    padding-bottom: 60%;
    background-size: cover;
    background-position: center center;
    margin-bottom: 12px;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
`;

const Name = styled.h3`
    font-size: 16px;
    font-weight: bold;
    text-decoration: none;
    margin-bottom: 4px;
    color: ${props => props.theme.primary};
`;

const Location = styled.h6`
    font-size: 16px;
    margin-bottom: 0px;
    color: ${props => props.theme.text};
`;

const Language = styled.div`
    background-color: ${props => props.theme.primary};
    position: absolute;
    top: 8px;
    right: 8px;
    color: #FFF;
    line-height: 32px;
    font-size: 12px;
    font-weight: bold;
    width: 32px;
    height: 32px;
    border-radius: 32px;
    text-align: center;
    text-transform: uppercase;
`;

