export const Castles = [
  {
    name: "Kasteel de Merode",
    uid: "579801078624",
    uri: "westerlo",
    lang: "nl",
    city: "Westerlo",
    province: "Antwerpen",
    image: "km_westerlo.jpg",
  },
  {
    name: "Kasteel van Laarne",
    uid: "763469504010",
    uri: "laarne",
    lang: "nl",
    city: "Laarne",
    province: "Oost-Vlaanderen",
    image: "km_laarne.jpg",
  },
  {
    name: "Kasteel Duras",
    uid: "097409031590",
    uri: "sint-truiden",
    lang: "nl",
    city: "Sint-Truiden",
    province: "Limburg",
    image: "km_sint_truiden.jpg",
  },
  {
    name: "Kasteel du Parc",
    uid: "040470443666",
    uri: "vlamertinge",
    lang: "nl",
    city: "Vlamertinge",
    province: "West-Vlaanderen",
    image: "km_vlamertinge.jpg",
  },
  {
    name: "Kasteel Wijnendale",
    uid: "364261708019",
    uri: "wijnendale",
    lang: "nl",
    city: "Wijnendale",
    province: "West-Vlaanderen",
    image: "km_wijnendale.jpg",
  },
  {
    name: "Kasteel Cleydael",
    uid: "128354319155",
    uri: "aartselaar",
    lang: "nl",
    city: "Aartselaar",
    province: "Antwerpen",
    image: "km_aartselaar.jpg",
  },
];

// Castles not open in 2024 season
// { name: 'Kasteel van Huldenberg', uid: '450403460623', uri: 'huldenberg',  lang: 'nl', city: 'Huldenberg', province: 'Vlaams-Brabant', image: 'km_huldenberg.jpg'},
// { name: "Kasteel d'Ursel", uid: "699313772767", uri: "hingene", lang: "nl", city: "Hingene", province: "Antwerpen", image: "km_hingene.jpg",},
// { name: 'Château de Deulin', uid: '336722462813', uri: 'hotton',  lang: 'fr', city: 'Hotton', province: 'Luxembourg', image: 'km_hotton.jpg'},
// { name: "Château de Louvignies", uid: "071955376968", uri: "soignies", lang: "fr", city: "Soignies", province: "Hainaut", image: "km_soignies.jpg",},
// { name: "Château de Corroy-le-Château", uid: "920423270290", uri: "gembloux", lang: "fr", city: "Gembloux", province: "Namur", image: "km_gembloux.jpg",},
