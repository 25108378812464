import styled from 'styled-components';

export const ButtonLink = styled.a`
    font-size: 16px;
    padding: 12px 24px;
    font-weight: bold;
    display: inline-block;
    text-decoration: none;
    color: ${ props => props.light ? props.theme.primary : '#FFFFFF'};
    background-color: ${ props => props.light ? '#FFFFFF' : props.theme.primary};
    border-radius: 4px;

    &:hover {
        color: ${ props => props.light ? props.theme.primaryLight : '#FFFFFF'};
        background-color: ${ props => props.light ? '#FFFFFF' : props.theme.primaryLight};
    }
`
