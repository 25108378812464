import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useState } from "react";

import { Error } from './Error';

export const VoucherBox = ({ closeVoucherBox, cart, fetchCart, show }) => {
  const [voucherCode, setVoucherCode] = useState("");
  const [error, setError] = useState(undefined);
  const { t } = useTranslation();

  const handleVoucherCodeChange = (e) => {
    setVoucherCode(e.target.value.toUpperCase());
  };

  const validateVoucher = (e) => {
    setError(undefined);
    e.preventDefault();
    const data = {
      voucher: voucherCode,
      show: show.id,
    };
    fetch(`/api/cart/${cart.cartid}/voucher`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
          return response
            .json()
            .then((data) => {
              throw data;
            })
            .catch((err) => {
              throw err;
            });
        } else {
          return response.json();
        }
      })
      .then((data) => {
        setVoucherCode("");
        fetchCart();
      })
      .catch((error) => {
        if (typeof error.error !== "undefined") {
          if(error.error === 'validation_failed'){
            if(typeof error.validation.show !== "undefined") {
              setError('validation_failed_show');
            }
            if(typeof error.validation.code !== "undefined") {
              setError('voucher_not_found');
            }
          } else {
            setError(error.error);
          }
        } else {
          setError('something_went_wrong');
        }
      });
  };
  return (
    <VoucherBoxStyle>
      <VoucherTitle>{t("voucher_box.title")}</VoucherTitle>
      <form onSubmit={validateVoucher}>
        <VoucherInputGroup>
          <VoucherInput
            value={voucherCode}
            onChange={handleVoucherCodeChange}
            type="text"
            autoFocus
            placeholder={t("voucher_box.placeholder")}
          />
          <VoucherButton type="submit">{t("voucher_box.validate")}</VoucherButton>
        </VoucherInputGroup>
      </form>
      <CloseIcon onClick={closeVoucherBox} src="/images/icon-close.svg" />
      <Error error={error} onClose={() => setError(undefined)}/>
    </VoucherBoxStyle>
  );
};

const VoucherBoxStyle = styled.div`
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
`;

const VoucherTitle = styled.h5`
  font-size: 16px;
  margin-bottom: 16px;
`;

const VoucherInputGroup = styled.div`
  display: grid;
  grid-template-columns: auto 100px;
  grid-gap: 16px;
`;

const VoucherInput = styled.input`
  display: block;
  border: 1px solid ${(props) => props.theme.text};
  border-radius: 4px;
  padding: 8px;
  box-sizing: border-box;
  font-size: 16px;

  &:focus {
    border: 1px solid ${(props) => props.theme.primaryLight};
    outline: none;
  }
`;

const VoucherButton = styled.button`
  display: block;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  padding: 4px 8px;
  background-color: ${(props) => props.theme.primary};
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: ${(props) => props.theme.primaryLight};
    outline: none;
    border: none;
  }
`;

const CloseIcon = styled.img`
  width: 16px;
  height: 16px;
  top: 16px;
  right: 16px;
  position: absolute;
  cursor: pointer;
`;
