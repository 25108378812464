import styled from "styled-components";

export const Checkbox = (props) => {
  const { value, onChange, children } = props;
  // const value = true;
  // const onChange = () => {console.log('change')};
  // const label = 'I KNOOOOOOOWWWW';
  return (
    <StyledCheckbox>
      <input type="checkbox" checked={value} onChange={onChange} />
      <span className="checkmark" />
      <span className="label">{children}</span>
    </StyledCheckbox>
  );
};

const StyledCheckbox = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .label {
      width: calc(100% - 36px);
  }

  .checkmark {
    /* position: absolute;
    top: 0;
    left: 0; */
    height: 24px;
    width: 24px;
    margin-right: 12px;
    background-color: ${props => props.theme.gray};
    border-radius: 4px;
    transition: all 200ms ease;
  }

  &:hover input ~ .checkmark {
    background-color: ${props => props.theme.grayDarker};
  }

  input:checked ~ .checkmark {
    background-color: ${props => props.theme.primary};
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark:after {
    left: 8px;
    top: 8px;
    width: 6px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;
