import styled, { css } from "styled-components"

export const Button = (props) => {
    const {children, onClick, block, disabled, link, type, autoFocus} = props;
    return (
        <StyledButton block={block} link={link} disabled={disabled} type={type} onClick={onClick} autoFocus={autoFocus}>{children}</StyledButton>
    )
}

const StyledButton = styled.button`
    cursor: pointer;
    appearance: none;
    outline: none;
    border: none;
    font-size: 16px;
    background-color: ${ props => props.theme.primary};
    color: #FFF;
    font-weight: bold;
    padding: 12px 24px;
    border-radius: 4px;
    /* transition: opacity .2s ease; */
    transition: all 200ms ease;

    &:hover {
        background-color: ${ props => props.theme.primaryLight};
    }

    ${props => props.block ? css`
        display: block;
        width: 100%;
    `: ''}

    ${props => props.link ? css`
        background: none;
        color: ${props => props.theme.gray };
    `: ''}

    ${props => props.type === 'gray' ? css`
        background: ${ props => props.theme.gray};
        color: ${ props => props.theme.text};

        &:hover {
            background: ${ props => props.theme.grayDarker};
        }
    `: ''}

    ${props => props.disabled ? css`
        opacity: .5;
        pointer-events: none;
        cursor: default;
    `: ''}
`;
