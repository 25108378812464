import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Link as StyledLink } from "./Link";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ButtonLink } from "./ButtonLink";
import { Hamburger } from "./Hamburger";
import { useState } from "react";

export const NavBar = (props) => {
  const { fetchCart } = props;

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [navWrapperOpen, setNavWrapperOpen] = useState(false);

  const changeLanguage = (e, lng) => {
    e.preventDefault();
    i18n.changeLanguage(lng);
    const newPath = location.pathname.replace("/fr", "").replace("/nl", "");
    navigate(`/${lng}${newPath}`, { replace: false });
    fetchCart();
  };

  const toggleNavWrapper = () => {
    setNavWrapperOpen(!navWrapperOpen);
  };

  return (
    <NavBarContainer>
      <HeaderWrapper>
        <StyledLink
          light
          href={
            i18n.languages[0] === "fr"
              ? "https://www.magiedenoel.be/"
              : "https://www.kerstmagie.be"
          }
          className="home-link"
        >
          {t("navbar.to_website")}
        </StyledLink>
        <Hamburger
          className="hamburger"
          onClick={toggleNavWrapper}
          isOpen={navWrapperOpen}
        />
        <LogoWrapperMobile to="/">
          <Logo
            src={
              i18n.languages[0] === "fr"
                ? "/images/magiedenoel_logo_white.svg"
                : "/images/kerstmagie_logo_white.svg"
            }
          />
        </LogoWrapperMobile>
        <LanguageMenu>
          <button
            onClick={(e) => changeLanguage(e, "nl")}
            className={i18n.languages[0] === "nl" ? "active" : ""}
          >
            NL
          </button>{" "}
          <span className="lang-separator">|</span>{" "}
          <button
            onClick={(e) => changeLanguage(e, "fr")}
            className={i18n.languages[0] === "fr" ? "active" : ""}
          >
            FR
          </button>
        </LanguageMenu>
      </HeaderWrapper>
      <LogoWrapper to="/">
        <Logo
          src={
            i18n.languages[0] === "fr"
              ? "/images/magiedenoel_logo_white.svg"
              : "/images/kerstmagie_logo_white.svg"
          }
        />
      </LogoWrapper>
      <NavWrapper className={navWrapperOpen ? "open" : ""}>
        <div>
          <div className="badge-new-story">{t("navbar.new_story")}</div>
          {/* <p>{t("navbar.description")}</p> */}
          <p>
            <b>{t("navbar.description_p1")}</b>
          </p>
          <p>{t("navbar.description_p2")}</p>
          <p>{t("navbar.description_p3")}</p>
          {/* <p>{t("navbar.description_p4")}</p>
          <p><b>{t("navbar.description_p5")}</b></p> */}
          <h5>{t("navbar.price_info")}</h5>
          <p>
            <span
              dangerouslySetInnerHTML={{ __html: t("navbar.price_info_adult") }}
            />
            <br />
            <span
              dangerouslySetInnerHTML={{ __html: t("navbar.price_info_child") }}
            />
          </p>
          <h5>{t("navbar.faq")}</h5>
          <p>{t("navbar.faq_description")}</p>
          <ButtonLink
            href={
              i18n.languages[0] === "fr"
                ? "https://www.magiedenoel.be/questions-frequentes/"
                : "https://www.kerstmagie.be/veelgestelde-vragen/"
            }
            target="_blank"
            light
          >
            {t("navbar.faq_link")}
          </ButtonLink>
          <NavFooter>
            <img src="/images/historalia-logo.svg" alt="historalia" />
            <p>
              <a
                target="_blank"
                rel="noreferrer"
                href={
                  i18n.languages[0] === "fr"
                    ? "https://docdro.id/WqbC7kB"
                    : "https://docdro.id/NS3IwD0"
                }
              >
                {t("navbar.terms_conditions")}
              </a>
            </p>
          </NavFooter>
        </div>
      </NavWrapper>
    </NavBarContainer>
  );
};

const NavBarContainer = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: ${(props) => props.theme.primary};
  height: 100%;
  width: 400px;
  height: 100%;
  padding: 48px;
  box-sizing: border-box;

  overflow: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  p {
    color: #fff;
  }
  h5 {
    color: #fff;
  }

  @media (max-width: 1100px) {
    position: relative;
    width: 100%;
    padding: 0;

    overflow: unset;
    -ms-overflow-style: unset; /* IE and Edge */
    scrollbar-width: unset;

    &::-webkit-scrollbar {
      display: unset;
    }
  }
`;

const HeaderWrapper = styled.div`
  margin-bottom: 48px;
  position: relative;

  .home-link:hover {
    color: #fff;
  }

  .hamburger {
    display: none;
  }

  @media (max-width: 1100px) {
    background-color: ${(props) => props.theme.primary};
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
    margin-bottom: 0;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);

    img {
      height: 54px;
      width: auto;
      margin-left: auto;
    }

    .home-link {
      display: none;
    }

    .hamburger {
      display: block;
    }
  }
`;

const LanguageMenu = styled.div`
  display: block;
  position: absolute;
  right: 0px;
  top: 0px;

  button {
    border: none;
    color: #fff;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    display: inline-block;
    cursor: pointer;

    &.active {
      font-weight: bold;
    }
  }

  .lang-separator {
    display: inline-block;
    color: #fff;
    margin: 0 4px;
  }

  @media (max-width: 1100px) {
    position: unset;
  }
`;

const LogoWrapper = styled(Link)`
  display: block;
  text-align: center;
  margin-bottom: 48px;

  @media (max-width: 1100px) {
    display: none;
  }
`;

const LogoWrapperMobile = styled(Link)`
  display: none;

  @media (max-width: 1100px) {
    display: block;
  }
`;

const Logo = styled.img`
  display: inline-block;
  width: 250px;
`;

const NavWrapper = styled.div.attrs((props) => ({
  className: props.className,
}))`
  p {
    white-space: pre-line;
  }

  .badge-new-story {
    background-color: ${(props) => props.theme.primaryLight};
    color: ${(props) => props.theme.primary};
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.8rem;
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    margin-bottom: 1rem;
    display: inline-block;
  }

  @media (max-width: 1100px) {
    box-sizing: border-box;
    //width: calc(100% - 32px);
    background-color: ${(props) => props.theme.primary};
    //padding: 32px;
    //display: none;
    max-height: 0px;
    overflow: hidden;
    position: relative;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
    transition: max-height 400ms ease;

    > div {
      padding: 32px;
    }

    &.open {
      overflow: auto;
      display: block;
      max-height: 1000px;
    }
  }
`;

const NavFooter = styled.div`
  margin-top: 32px;

  img {
    margin-bottom: 16px;
  }

  a {
    text-decoration: none;

    &:hover {
      color: #fff;
    }
  }
`;
