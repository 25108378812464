import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import ReactPixel from 'react-facebook-pixel';

export const Analytics = (store) => {
  const location = useLocation();

  ReactGA.initialize("G-9XSQ6691CC");
  
  useEffect(() => {
    ReactGA.send("pageview");
    ReactPixel.pageView();
  }, [location]);

  useEffect(() => {
    if(store){
      const fbPixel = store.integrations.find((integration) => integration.type === "facebook_pixel");
      if(fbPixel){
        console.log('init pixel');
        ReactPixel.init(fbPixel.credentials.pixel_id);
        ReactPixel.pageView();
      }
    }
  }, [store]);
};

