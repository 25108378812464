import React from "react";
import styled from "styled-components";
import { LocalLink } from "./LocalLink";

export const PageTitle = (props) => {
    const { title, icon, subtitle, linkTo, linkLabel, children } = props;
    return (
        <div>
            { linkTo &&
                <BackLink to={linkTo}><BackIcon src="/images/icon-arrow-left.svg"/><span>{linkLabel}</span></BackLink>
            }
            <PageTitleWrapper>
                <Icon src={`/images/${icon}`}/>
                <div>
                    <PageTitleText>{title}</PageTitleText>
                    { subtitle && <SubTitleText>{subtitle}</SubTitleText>}
                </div>
                { children }
            </PageTitleWrapper>
        </div>
    )
}

const BackLink = styled(LocalLink)`
    display: block;
    margin-bottom: 32px;
    text-decoration: none;
    display: grid;
    grid-template-columns: 24px auto;
    grid-gap: 16px;
    align-items: center;
`;

const PageTitleWrapper = styled.div`
    display: grid;
    grid-template-columns: 44px auto;
    grid-gap: 16px;
    align-items: center;
    padding-bottom: 24px;
    border-bottom: 1px solid #CCC;
    margin-bottom: 32px;
`;

const BackIcon = styled.img`
    display: block;
`;

const Icon = styled.img`
    display: block;
`;

const PageTitleText = styled.h2`
  color: ${props => props.theme.text };
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 0px;
`;

const SubTitleText = styled.h3`
  color: ${props => props.theme.text };
  font-size: 16px;
  margin-bottom: 0px;
  margin-top: 4px;
`;