import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

import { ButtonLink } from "../components/ButtonLink";

export const StoreClosed = () => {
  const { locale } = useParams();
  const { i18n } = useTranslation();
  const activeLanguage = i18n.languages[0];

  useEffect(() => {
    if (activeLanguage !== locale) {
      i18n.changeLanguage(locale);
    }
  }, [locale, activeLanguage]); // eslint-disable-line react-hooks/exhaustive-deps

  //   return (
  //         <Page>
  //             <Content>
  //                 { i18n.languages[0] === 'fr' ? <Logo src="/images/magiedenoel_logo_white.svg"/> : <Logo src="/images/kerstmagie_logo_white.svg"/>}
  //                 <Title>{t('store_closed.title_next_year')}</Title>
  //                 <Message>{t('store_closed.lead_next_year')}</Message>
  //                 <ButtonLink target="_blank" light href={ i18n.languages[0] === 'fr' ? "https://www.magiedenoel.be/" : "https://www.kerstmagie.be"}>{t('store_closed.visit_site')}</ButtonLink>
  //             </Content>
  //         </Page>
  //     )

  return (
    <Page>
      <Content>
        {i18n.languages[0] === "fr" ? (
          <Logo src="/images/magiedenoel_logo_white.svg" />
        ) : (
          <Logo src="/images/kerstmagie_logo_white.svg" />
        )}
        <Title>{t("store_closed.title")}</Title>
        <Message>{t("store_closed.lead")}</Message>
        <ButtonLink
          target="_blank"
          light
          href={
            i18n.languages[0] === "fr"
              ? "https://www.magiedenoel.be/"
              : "https://www.kerstmagie.be"
          }
        >
          {t("store_closed.visit_site")}
        </ButtonLink>
      </Content>
    </Page>
  );
};

const Page = styled.div`
  background-color: ${(props) => props.theme.primary};
  width: 100%;
  height: 100vh;
  text-align: center;
`;

const Content = styled.div`
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 600px;
  top: 50%;
  text-align: center;
  transform: translateY(-50%);
`;

const Logo = styled.img`
  display: inline-block;
  width: 250px;
  margin-bottom: 48px;
`;

const Title = styled.h1`
  color: #fff;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
`;

const Message = styled.p`
  font-size: 20px;
  color: #fff;
  margin-bottom: 48px;
`;
