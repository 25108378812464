import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Button } from "./Button";

export const Error = (props) => {
  const { error, onClose } = props;
  const { t } = useTranslation();

  const getErrorMessage = () => {
    switch (error) {
      case "cart_expired":
        return {
          title: t("errors.cart_expired.title"),
          message: t("errors.cart_expired.message"),
          handle: () => {
            localStorage.removeItem("esq-cart");
            window.location.reload();
          },
        };
      case "max_quantity":
        return {
          title: t("errors.max_quantity.title"),
          message: t("errors.max_quantity.message"),
        };
      case "out_of_stock":
        return {
          title: t("errors.out_of_stock.title"),
          message: t("errors.out_of_stock.message"),
        };
      case "validation_failed":
        return {
          title: t("errors.validation_failed.title"),
          message: t("errors.validation_failed.message"),
        };
      case "validation_failed_show":
        return {
          title: t("errors.validation_failed_show.title"),
          message: t("errors.validation_failed_show.message"),
        };
      case "voucher_not_available":
          return {
            title: t("errors.voucher_not_available.title"),
            message: t("errors.voucher_not_available.message"),
          };
      case "voucher_not_found":
        return {
          title: t("errors.voucher_not_found.title"),
          message: t("errors.voucher_not_found.message"),
        };
      case "something_went_wrong":
        return {
          title: t("errors.something_went_wrong.title"),
          message: t("errors.something_went_wrong.message"),
        };
      default:
        return {
          title: t("errors.default.title"),
          message: t("errors.default.message"),
        };
    }
  };

  if (!error) return null;

  const errorMessage = getErrorMessage();
  const closeHandler =
    typeof errorMessage.handler !== "undefined"
      ? errorMessage.handler
      : onClose;

  return (
    <PageError>
      <ErrorModal>
        <h5>{errorMessage.title}</h5>
        <p>{errorMessage.message}</p>
        <Button autoFocus onClick={closeHandler}>{t("errors.close")}</Button>
      </ErrorModal>
    </PageError>
  );
};

const PageError = styled.div`
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  box-sizing: border-box;
  padding: 48px;
`;

const ErrorModal = styled.div`
  background-color: #fff;
  width: 100%;
  max-width: 600px;
  box-sizing: border-box;
  padding: 32px;
  border-radius: 4px;
  margin: 0 auto;
  text-align: center;

  position: relative;
  top: 50%;
  transform: translateY(-50%);

  button {
    margin: 8px;
  }
`;
