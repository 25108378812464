import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";
import { useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet";

import { PageTitle } from "../components/PageTitle";
import { Button } from "../components/Button";
import { Preloader } from "../components/Preloader";

const calendarDates = () => {
  const start = new Date("2024/12/02");
  const end = new Date("2024/12/29");
  for (
    var arr = [], dt = new Date(start);
    dt <= end;
    dt.setDate(dt.getDate() + 1)
  ) {
    arr.push(new Date(dt));
  }
  return arr;
};

export const PageDates = (props) => {
  const {
    activeCastle,
    store,
    selectedDate,
    selectedTimeslot,
    setSelectedDate,
    setSelectedTimeslot,
  } = props;

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [showDates, setShowDates] = useState(undefined);
  const [dateList, setDateList] = useState([]);
  const [timeslotList, setTimeslotList] = useState([]);

  const timesRef = useRef();
  const continueRef = useRef();

  const activeLanguage = i18n.languages[0];

  useEffect(() => {
    if (!store) return;
    filterShows();
  }, [store]); // eslint-disable-line react-hooks/exhaustive-deps

  const filterShows = () => {
    let showDatesList = store.channel.shows.map((show) => {
      const showDate = new Date(show.date.start.replace(/-/g, "/"));
      return new Date(
        `${showDate.getFullYear()}/${
          showDate.getMonth() + 1
        }/${showDate.getDate()}`
      );
    });
    setShowDates([...new Set(showDatesList)]);
  };

  useEffect(() => {
    if (!showDates) return;
    const dates = calendarDates().map((date) => {
      return {
        date: date,
        disabled: isDateDisabled(date),
      };
    });
    setDateList(dates);
  }, [showDates]); // eslint-disable-line react-hooks/exhaustive-deps

  const isDateActive = (date) => {
    if (!selectedDate) return false;
    return date.toUTCString() === selectedDate.toUTCString();
  };

  const isDateDisabled = (date) => {
    if (!showDates.length) return true;
    const shows = showDates.filter((showDate) => {
      return (
        date.getFullYear() === showDate.getFullYear() &&
        date.getMonth() === showDate.getMonth() &&
        date.getDate() === showDate.getDate()
      );
    });
    return !shows.length;
  };

  const handleSelectDate = (date) => {
    setSelectedTimeslot(undefined);
    setSelectedDate(date);
  };

  useEffect(() => {
    if (!selectedDate || !store) {
      setTimeslotList([]);
    } else {
      const timeslots = store.channel.shows
        .filter((show) => {
          const showDate = new Date(show.date.start.replace(/-/g, "/"));
          return (
            showDate.getFullYear() === selectedDate.getFullYear() &&
            showDate.getMonth() === selectedDate.getMonth() &&
            showDate.getDate() === selectedDate.getDate()
          );
        })
        .map((timeslot) => {
          return {
            date: new Date(timeslot.date.start.replace(/-/g, "/")),
            timeslot: timeslot,
          };
        });
      setTimeslotList(timeslots);
    }
  }, [selectedDate, store]);

  useEffect(() => {
    if (!!timeslotList.length) {
      timesRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  }, [timeslotList]);

  useEffect(() => {
    if (selectedTimeslot) {
      continueRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  }, [selectedTimeslot]);

  const handleSelectTimeslot = (timeslot) => {
    setSelectedTimeslot(timeslot);
  };

  const isActiveTimeslot = (id) => {
    if (!selectedTimeslot) return false;
    return selectedTimeslot.id === id;
  };

  const handleButtonCart = () => {
    navigate(`/${activeLanguage}/${activeCastle.uri}/cart`);
  };

  const getTimeslotStatus = (timeslot) => {
    if (timeslot.status === "soldout") {
      return "soldout";
    }
    if (timeslot.attendees.available <= 10) {
      return "low";
    }
    return "available";
  };

  return (
    <>
      <Helmet>
        <title>
          {i18n.languages[0] === "fr"
            ? `${activeCastle.name} - Magie de Noël`
            : `${activeCastle.name} - Kerstmagie`}
        </title>
      </Helmet>
      <PageTitle
        linkTo="/"
        linkLabel={t("dates_page.change_castle")}
        icon="icon-calendar.svg"
        title={`${activeCastle.name} - ${activeCastle.city}`}
        subtitle={t("dates_page.date_sub_title")}
      />
      {showDates === undefined && (
        <div>
          <Preloader />
        </div>
      )}
      <Calendar>
        {dateList.map(({ date, disabled }) => {
          return (
            <CalendarDay
              key={date.getTime()}
              active={isDateActive(date)}
              disabled={disabled}
              onClick={() => handleSelectDate(date)}
            >
              <Day>
                {date.toLocaleString(i18n.language, { weekday: "long" })}
              </Day>
              <DateText>{date.getDate()}</DateText>
              <Month>
                {date.toLocaleString(i18n.language, { month: "long" })}
              </Month>
            </CalendarDay>
          );
        })}
      </Calendar>
      {selectedDate && (
        <div ref={timesRef}>
          <h5>{t("dates_page.time_title")}</h5>
          <p>{t("dates_page.time_sub_title")}</p>
          <TimeSlots>
            {timeslotList.map(({ timeslot, date }) => {
              return (
                <TimeSlot
                  key={timeslot.id}
                  active={isActiveTimeslot(timeslot.id)}
                  $status={getTimeslotStatus(timeslot)}
                  onClick={() => {
                    if (timeslot.status !== "soldout") {
                      handleSelectTimeslot(timeslot);
                    }
                  }}
                >
                  <Time>
                    {date.getHours()}:
                    {String(date.getMinutes()).padStart(2, "0")}
                  </Time>

                  {timeslot.attendees.available > 10 &&
                    timeslot.status !== "soldout" && (
                      <Status>{t("dates_page.status.available")}</Status>
                    )}

                  {timeslot.attendees.available <= 10 &&
                    timeslot.status !== "soldout" && (
                      <Status>
                        {t("dates_page.status.low_1")}{" "}
                        {timeslot.attendees.available}{" "}
                        {t("dates_page.status.low_2")}
                      </Status>
                    )}
                  {timeslot.status === "soldout" && (
                    <Status>{t("dates_page.status.soldout")}</Status>
                  )}
                </TimeSlot>
              );
            })}
          </TimeSlots>
        </div>
      )}
      {selectedTimeslot && (
        <div ref={continueRef}>
          <Button onClick={handleButtonCart}>{t("dates_page.continue")}</Button>
        </div>
      )}
    </>
  );
};

const Calendar = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 16px;
  margin-bottom: 48px;

  @media (min-width: 1100px) and (max-width: 1260px) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media (max-width: 840px) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media (max-width: 680px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const CalendarDay = styled.div`
  background-color: white;
  text-align: center;
  padding: 12px;
  box-sizing: border-box;
  transition: transform ease 0.2s, background-color ease 0.1s;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
  }

  ${(props) =>
    props.active
      ? css`
          transform: scale(1.05);
          background-color: ${(props) => props.theme.primaryLight};

          ${Day}, ${DateText}, ${Month} {
            color: #fff;
          }
        `
      : ""}

  ${(props) =>
    props.disabled
      ? css`
          pointer-events: none;
          cursor: default;

          ${Day}, ${DateText}, ${Month} {
            opacity: 0.2;
          }
        `
      : ""}
`;

const Day = styled.div`
  font-size: 12px;
  margin-bottom: 8px;
  text-transform: uppercase;
  transition: color ease 0.1s;
`;
const DateText = styled.div`
  font-size: 32px;
  font-weight: bold;
  color: ${(props) => props.theme.primary};
  margin-bottom: 12px;
  transition: color ease 0.1s;
`;
const Month = styled.div`
  font-size: 12px;
  text-transform: uppercase;
  transition: color ease 0.1s;
`;

const TimeSlots = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 16px;
  margin-bottom: 48px;

  @media (max-width: 680px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const TimeSlot = styled.div`
  background-color: #fff;
  text-align: center;
  padding: 16px 16px 12px;
  box-sizing: border-box;
  transition: transform ease 0.2s, background-color ease 0.1s;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  cursor: pointer;

  border-bottom: 4px solid #fff;

  &:hover {
    transform: scale(1.05);
  }

  ${(props) =>
    props.$status === "soldout"
      ? css`
          cursor: default;
          pointer-events: none;
          border-bottom: 4px solid ${(props) => props.theme.grayDarker};

          ${Time}, ${Status} {
            opacity: 0.2;
          }
        `
      : ""}

  ${(props) =>
    props.$status === "low"
      ? css`
          border-bottom: 4px solid ${(props) => props.theme.stockLow};
        `
      : ""}

  ${(props) =>
    props.$status === "available"
      ? css`
          border-bottom: 4px solid ${(props) => props.theme.stockAvailable};
        `
      : ""}

  ${(props) =>
    props.active
      ? css`
          transform: scale(1.05);
          background-color: ${(props) => props.theme.primaryLight};
          border-bottom: 4px solid ${(props) => props.theme.primaryLight};

          ${Time}, ${Status} {
            color: #fff;
          }
        `
      : ""}
`;

const Time = styled.div`
  font-size: 20px;
  margin-bottom: 6px;
  font-weight: bold;
  color: ${(props) => props.theme.text};
`;

const Status = styled.div`
  font-size: 12px;
  margin-bottom: 0px;
  font-weight: bold;
  color: ${(props) => props.theme.text};
`;
