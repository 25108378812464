import styled from "styled-components";
import { useState } from "react";

import { Error } from "./Error";

export const HeritaBox = ({ closeHeritaBox, cart, fetchCart }) => {
  const [memberId, setMemberId] = useState("");
  const [error, setError] = useState(undefined);

  const handleMemberIdChange = (e) => {
    setMemberId(e.target.value.toUpperCase());
  };

  const validateMemberId = (e) => {
    setError(undefined);
    e.preventDefault();
    const data = {
      member_id: memberId,
    };
    fetch(`/api/cart/${cart.cartid}/herita`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
          return response
            .json()
            .then((data) => {
              throw data;
            })
            .catch((err) => {
              throw err;
            });
        } else {
          return response.json();
        }
      })
      .then((data) => {
        setMemberId("");
        fetchCart();
      })
      .catch((error) => {
        console.error(error);
        if (typeof error.error !== "undefined") {
          setError(error.error);
        } else {
          setError("something_went_wrong");
        }
      });
  };

  return (
    <VoucherBoxStyle>
      <VoucherTitle>Aanmelden Herita-leden</VoucherTitle>
      <p>
        Als Herita en Herita Plus lid kan je tickets voor Kerstmagie Laarne
        reserveren.
      </p>
      <form onSubmit={validateMemberId}>
        <VoucherInputGroup>
          <VoucherInput
            value={memberId}
            onChange={handleMemberIdChange}
            type="text"
            autoFocus
            placeholder="17XXXXXXXXXXX"
          />
          <VoucherButton type="submit">Aanmelden</VoucherButton>
        </VoucherInputGroup>
      </form>
      <CloseIcon onClick={closeHeritaBox} src="/images/icon-close.svg" />
      <Error error={error} onClose={() => setError(undefined)} />
    </VoucherBoxStyle>
  );
};

const VoucherBoxStyle = styled.div`
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
`;

const VoucherTitle = styled.h5`
  font-size: 16px;
  margin-bottom: 16px;
`;

const VoucherInputGroup = styled.div`
  display: grid;
  grid-template-columns: auto 100px;
  grid-gap: 16px;
`;

const VoucherInput = styled.input`
  display: block;
  border: 1px solid ${(props) => props.theme.text};
  border-radius: 4px;
  padding: 8px;
  box-sizing: border-box;
  font-size: 16px;

  &:focus {
    border: 1px solid ${(props) => props.theme.primaryLight};
    outline: none;
  }
`;

const VoucherButton = styled.button`
  display: block;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  padding: 4px 8px;
  background-color: ${(props) => props.theme.primary};
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: ${(props) => props.theme.primaryLight};
    outline: none;
    border: none;
  }
`;

const CloseIcon = styled.img`
  width: 16px;
  height: 16px;
  top: 16px;
  right: 16px;
  position: absolute;
  cursor: pointer;
`;
