import { t } from 'i18next';
import {  useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

import { PageTitle } from '../components/PageTitle';
import { CastleList } from '../components/CastleList';
import { CastleError } from '../components/CastleError';

export const PageCastle = ({ cart }) => {
  const { locale } = useParams();
  const { i18n } = useTranslation();
  const activeLanguage = i18n.languages[0];

  useEffect(() => {
    if (activeLanguage !== locale) {
      i18n.changeLanguage(locale);
    }
  }, [locale, activeLanguage]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <CastleError cart={cart} castlePage={true} />
      <PageTitle
        icon="icon-castle.svg"
        title={t("castle_page.title")}
        subtitle={t("castle_page.sub_title")}
      />
      <CastleList />
    </>
  );
};