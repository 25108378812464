import styled, { keyframes } from "styled-components"

export const Preloader = () => <DualRing />

const DualRingRotation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const DualRing = styled.div`
    display: inline-block;
    width: 36px;
    height: 36px;

    &:after {
        content: " ";
        display: block;
        width: 28px;
        height: 28px;
        margin: 2px;
        border-radius: 50%;
        border: 2px solid ${props => props.theme.primary};
        border-color: ${props => props.theme.primary} transparent ${props => props.theme.primary} transparent;
        animation: ${DualRingRotation} .8s linear infinite;
    }
`;

