import styled, { css } from "styled-components"

export const Alert = ({children, danger}) => {
    return (
        <AlertBox danger={danger}>
            <p>{children}</p>
        </AlertBox>
    )
}

const AlertBox = styled.div`
    background-color: ${props => props.theme.warning };
    padding: 20px;
    box-sizing: border-box;
    margin-bottom: 16px;
    border-radius: 4px;

    p {
        margin-bottom: 0px;
    }

    ${props => props.danger && css`
    background-color: ${props => props.theme.dangerLight };
    `}
`
