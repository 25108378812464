import styled from "styled-components";

export const Hamburger = (props) => {
    const { onClick, isOpen, className } = props;
    return (
        <StyledHamburger
                onClick={onClick}
                className={className}
              >
                <div
                  className={`hamburger-icon ${
                    isOpen ? "hamburger-icon-open" : ""
                  }`}
                >
                  <span />
                </div>
        </StyledHamburger>
    )
};

const StyledHamburger = styled.div.attrs(props => ({ className: props.className}))`
    .hamburger-icon {
            width: 32px;
            height: 32px;
            position: relative;

            &:before,
            &:after {
              content: "";
              background-color: #FFF;
              width: 24px;
              height: 2px;
              //transition: all 0.2s ease;
              position: absolute;
              left: 4px;
              top: 8px;
            }

            &:after {
              top: 22px;
            }

            span {
              background-color: #FFF;
              display: block;
              width: 24px;
              height: 2px;
              position: absolute;
              left: 4px;
              top: 15px;
              //transition: all 0.2s ease;
              opacity: 1;
            }

            &.hamburger-icon-open {
              span {
                opacity: 0;
              }

              &:before {
                transform: rotate(45deg);
                top: 16px;
              }
              &:after {
                transform: rotate(-45deg);
                top: 16px;
              }
            }
          }
`;