import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import { Reset } from 'styled-reset'
import { Suspense } from 'react';
import { BrowserRouter as Router,  } from 'react-router-dom';
import * as Sentry from "@sentry/react";

import App from './App';
import './i18n';

Sentry.init({
  dsn: "https://ae6790ad8b414489bea417ec5a65a7d0@o543793.ingest.sentry.io/4505515691278336",
});

const Spinner = () => {
  return <div></div>
}

const theme = {
  light: "#F9FAFB",
  primary: "#172856",
  primaryLight: "#6EC1E4",
  text: "#303944",
  gray: "#E6E9ED",
  grayDarker: "#B8BFC9",
  warning: "#FFEEB2",
  dangerLight: "#FFB2B2",
  dangerDark: "#E94C4C",
  stockLow: "#FFD600",
  stockAvailable: "#8ECD89"
};

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${props => props.theme.light};
    font-size: 16px;
    font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
    color: ${props => props.theme.text};
  }
  p, h1, h2, h3, h4, h5, h5, h6 {
    margin-bottom: 24px;
  }
  p {
    line-height: 1.2;
  }
  a {
    color: ${props => props.theme.primaryLight};

    &:visited {
      color: ${props => props.theme.primaryLight};
    }
    
    &:hover {
      color: ${props => props.theme.primary};
    }
  }
  h3 {
    font-size: 24px;
    font-weight: bold;
  }
  h5 {
    font-size: 20px;
    font-weight: bold;
  }

  b, strong {
    font-weight: bold;
  }
`

ReactDOM.render(
  <React.StrictMode>
    <Reset/>
    <ThemeProvider theme={theme}>  
      <GlobalStyle />
      <Suspense fallback={<Spinner />}>
        <Router>
          <App />
        </Router>
      </Suspense>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
