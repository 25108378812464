import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { Castles } from "../lib/Data";
import { Button } from './Button';

export const CastleError = ({ cart, activeCastle, castlePage}) => {

    const [cartCastle, setCartCastle] = useState(undefined);
    const [showError, setShowError] = useState(false);

    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if(!cart) return;
        const cartCastleData = Castles.find(castle => castle.uid === cart.belongsto.edition);
        if(cartCastleData) setCartCastle(cartCastleData);
        if (activeCastle && cartCastleData.uri !== activeCastle.uri) {
            setShowError(true);
        } else if(castlePage && cartCastleData) {
            setShowError(true);
        } else {
            setShowError(false);
        }
    }, [cart, activeCastle, castlePage]);

    const handleContinue = () => {
        const activeLanguage = i18n.languages[0];
        navigate(`/${activeLanguage}/${cartCastle.uri}`);
    }
    const handleReset = () => {
        localStorage.removeItem('esq-cart');
        window.location.reload();
    }

    if(!showError) return null;

    return (
        <PageError>
            <ErrorModal>
                <h5>{t('castle_error.title')}</h5>
                <p>{t('castle_error.lead_1')} {cartCastle.name} {t('castle_error.lead_2')}</p>
                <Button onClick={handleContinue}>{t('castle_error.continue')}</Button> <Button onClick={handleReset}>{t('castle_error.cancel')}</Button>
            </ErrorModal>
        </PageError>
    )
}

const PageError = styled.div`
    background-color: rgba(0,0,0,0.6);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
    box-sizing: border-box;
    padding: 48px;
`;

const ErrorModal = styled.div`
    background-color: #FFF;
    width: 100%;
    max-width: 600px;
    box-sizing: border-box;
    padding: 32px;
    border-radius: 4px;
    margin: 0 auto;
    text-align: center;

    position: relative;
    top: 50%;
    transform: translateY(-50%);
    
    button {
        margin: 8px;
    }
`;
